<template>
  <div>
    <v-dialog v-model="dialogCreate" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small color="success" dark v-bind="attrs" v-on="on">
          {{ $t('MENU.ENTERPRICE.INCOMES.MAIN') }} +
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Asosiy Daromad qo'shish</span>
        </v-card-title>
        <v-card-text>
          <div style="display: flex">
            <div class="col-6 pl-0">
              <v-text-field
                label="Nomi"
                v-model="nameInput"
                outlined
                dense
              ></v-text-field>
            </div>
            <div class="col-6 pr-0">
              <v-text-field
                label="Kod"
                v-model="codeInput"
                maxLength="4"
                outlined
                dense
              ></v-text-field>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            :disabled="newmainprofitLoading"
            text
            @click="dialogCreate = false"
            >Bekor qilish</v-btn
          >
          <v-btn
            color="green darken-1"
            :disabled="newmainprofitLoading"
            text
            @click="submit"
          >
            <i v-if="newmainprofitLoading" class="el-icon-loading"></i>
            Yuborish</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newmainprofitLoading: false,
      dialogCreate: false,
      nameInput: '',
      codeInput: ''
    }
  },
  methods: {
    submit() {
      const data = {
        name: this.nameInput,
        code: this.codeInput
      }
      this.newmainprofitLoading = true
      this.$store
        .dispatch('createMainProfit', data)
        .then(() => {
          this.newmainprofitLoading = false
          this.nameInput = ''
          this.codeInput = ''
          this.dialogCreate = false
        })
        .catch((err) => {
          this.newmainprofitLoading = false
          console.error(err)
        })
    }
  }
}
</script>

<style></style>
